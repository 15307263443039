(function ($) {
    let search__result = $('.search__result-box');

    $('.search__input').on('click', function () {
        if ( $(window).width() < 768 ) {
            $('.bottom-menu').addClass('d-none');
            $('.search').addClass('mobile');
        }
        search__result.removeClass('d-none');
    })

    $(document).on('mouseup', function (e) {
        var container = search__result;
        if (container.has(e.target).length === 0){
            if ( $(window).width() < 768 ) {
                $('.bottom-menu').removeClass('d-none');
                $('.search').removeClass('mobile');
            }
            container.addClass('d-none');
        }
    });

    $('[data-modal]').on('click', function(e) {
        e.preventDefault();
        $('.modal').addClass('d-none');
        let modalName = $(this).data('modal');
        $('.'+modalName).removeClass('d-none');
    })

    $('.modal__close').on('click', function() {
        $(this).closest('.modal').addClass('d-none');
    })

    $('.header__languages-img-active').on('click', function() {
        $('.header__languages').removeClass('d-none');
    })

    $('.header__languages-item').on('click', function() {
        $('.header__languages').addClass('d-none');
    })
})(jQuery);